import React, { useState } from 'react'
import axios from 'axios';

export const useNominatim = () => {
    const [coordenadas, setCoordenadas] = useState({
        latitud: null,
        longitud: null
    })
    const [calle, setCalle] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null)

    const [fetching, setFetching] = useState(false);
    //@ts-ignore
    const obtenerCoordenadas = async (calle, altura, localidad = 'Bahía Blanca', provincia = 'Buenos Aires') => {
        setErrorMessage(null);
        setFetching(true);
        const url = `https://nominatim.openstreetmap.org/search/${calle} ${altura},${localidad},${provincia},Argentina?format=json&addressdetails=1&limit=1&polygon_svg=0`;

        const { data } = await axios.get(url)



        return (data)


        if (data.length > 0) {

            setCoordenadas({
                latitud: data[0].lat,
                longitud: data[0].lon
            })
            setCalle(data[0].address.road)
        } else {
            //@ts-ignore
            setErrorMessage('No se puedo encontrar la dirección ingresada.');
        }
        setFetching(false);
    }
    //@ts-ignore
    const setLatLon = (latitud, longitud) => {
        setCoordenadas({
            latitud,
            longitud
        })
    }

    const resetCoordenadas = () => {
        setCoordenadas({
            latitud: null,
            longitud: null
        })
        setCalle(null)
        setErrorMessage(null)
    }

    return {
        fetching,
        calle,
        coordenadas,
        errorMessage,
        obtenerCoordenadas,
        resetCoordenadas,
        setLatLon
    }
}
