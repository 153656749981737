import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { Image, UserResponse } from "../../interfaces/Use-interface";
//import { AxiosRequest } from "../../../helpers/axiosInstance";
//import { Profile } from "../../interfaces/data-interface";
import {
  axiosLoggedInConfig,
  axiosLoggedOutConfig,
} from "../../../utilities/Axios";

export const login = createAsyncThunk(
  "user/loginForm",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axiosLoggedOutConfig.post("login/", data);
      //@ts-ignore
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("lgac", response.data.access);
      localStorage.setItem("lgrf", response.data.refresh);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const generateToken = createAsyncThunk(
  "user/generateToken",
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await axiosLoggedInConfig().get("newalpha/");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const doubleFactor = createAsyncThunk(
  "user/doubleFactor",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axiosLoggedOutConfig.post(
        "doublefactor/",
        data
      );
      //@ts-ignore
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("lgac", response.data.access);
      localStorage.setItem("lgrf", response.data.refresh);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginAfip = createAsyncThunk(
  "user/changeUsernameAfip",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosLoggedOutConfig.get(
        `afipprofile/?token=${data}`
      );
      //@ts-ignore
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("lgac", response.data.access);
      localStorage.setItem("lgrf", response.data.refresh);
      return response;
    } catch (error) {
    }
  }
);

export const getLocations = createAsyncThunk("get/locations", async () => {
  try {
    const response = await axiosLoggedOutConfig.get(`locations/`);
    //@ts-ignore
    const rsOptions = response.data.map((x) => ({ label: x, value: x }));
    return rsOptions;
  } catch (error) {
  }
});

export const getCurrentProfile = createAsyncThunk(
  "get/profileform",
  async () => {
    try {
      const response = await axiosLoggedInConfig().get(`userprofile/`);
      //@ts-ignore
      return response.data;
    } catch (error: any) {
    }
  }
);
interface Pros {
  loading: boolean;
  changeusername: boolean;
  changepassword: boolean;
  user: {
    id: number;
    dni: number;
    first_name: string;
    last_name: string;
    email: string;
    kind_of_person: any;
    register_from: any;
  };
  error: {
    status?: number;
    message: string;
    isPinError?: boolean;
  };
  locations?: any;
}

const initialState: Pros = {
  loading: false,
  changeusername: false,
  changepassword: false,
  user: {
    id: 0,
    dni: 0,
    first_name: "",
    last_name: "",
    email: "",
    kind_of_person: {},
    register_from: {},
  },
  error: {
    status: 0,
    message: "",
    isPinError: false,
  },
  locations: [],
};

const userSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action: any) => {
      state.loading = false;
      state.error = {
        status: action.payload.response.status,
        message: action.payload.response.data.detail,
      };
    });
    builder.addCase(generateToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateToken.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(generateToken.rejected, (state, action: any) => {
      state.loading = false;
      state.error = {
        status: action.payload.response.status,
        message: action.payload.response.data.detail,
      };
    });
    builder.addCase(doubleFactor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(doubleFactor.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(doubleFactor.rejected, (state, action: any) => {
      state.loading = false;
      state.error = {
        message: action.payload.detail,
        isPinError: true,
      };
    });
    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.locations = payload;
    });
    builder.addCase(getLocations.rejected, (state, action: any) => {
      state.loading = false;
      state.error = {
        message: action.payload.detail,
      };
    });
    builder.addCase(getCurrentProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCurrentProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    });
    builder.addCase(getCurrentProfile.rejected, (state, action: any) => {
      state.loading = false;
    });
  },
});

export default userSlice.reducer;
