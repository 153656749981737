import * as React from "react";
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  useDisclosure,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Flex,
  CloseButton,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  useBreakpointValue,
  Tag,
} from "@chakra-ui/react";
import { Link as LinkDom } from 'react-router-dom';
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { Logo } from "../../Logo";
import { AutenticaIcon, CalendarioIcon, DocumentosIcon, EscritorioIcon, MiBahiaIcon, NotificacionesIcon, ReclamosIcon, SaludIcon, ServiciosIcon, TramitesIcon } from "../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  FiEdit,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiHome,
  FiCalendar,
  FiSearch,
} from "react-icons/fi";
import { AppDispatch } from "../../store/StoreRoot";
import { getCurrentProfile } from "../auth/_duck";
import { Home } from "../Home";
import { act } from "react-dom/test-utils";
import { EditProfile } from "../EditProfile";
import { LoadSpinner } from "../../components/Spinner";

const LinkItems = [
  { name: "Escritorio", icon: EscritorioIcon, id: 1, path: "/", central: true },
  { name: "Alertas", icon: CalendarioIcon, id: 2, path: "/calendario" },

  { name: "Mis documentos", icon: NotificacionesIcon, id: 2, path: "/notificaciones", disabled: false },
  { name: "Vencimientos", icon: ServiciosIcon, id: 2, path: "/servicios" },
  { name: "Licencia de Conducir", icon: ReclamosIcon, id: 2, path: "/reclamos" },
  { name: "Reclamos", icon: ReclamosIcon, id: 2, path: "/reclamos" },
];

export const SidebarWithHeader = ({ children }: { children: React.ReactNode }) => {
  //@ts-ignore
  const user = useSelector((state) => state.profile);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = React.useState(1);
  const color = useColorModeValue("gray.100", "gray.900");

  return (
    <>
      <Box minH="100vh" bg={color}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 300 }} p="4">
          {user.loading && <LoadSpinner />}
          {activeTab === 1 && !user.loading && children}
          {activeTab === 2 && <EditProfile />}
        </Box>
      </Box>
    </>
  );
};

//@ts-ignore
const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (

    <Box
      transition="3s ease"
      bg={useColorModeValue({ base: '#4383D4', md: 'white' }, "gray.900")}
      w={{ base: "full", md: 300 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Box display={{ base: "flex", md: "none" }} bg={"white"} h={16} alignItems={"center"} justifyContent="center" mx="auto">
        <MiBahiaIcon display={{ base: "flex", md: "none" }} w="30%" h="100%" />
      </Box>
      <Flex h="16" alignItems="center" mx="8" justifyContent="space-between" >
        <Icon as={MiBahiaIcon} w={{ base: "40%", md: "60%" }} display={{ base: 'none', md: 'flex' }} h="100%" />
        <HStack>
          <Icon as={FiHome} color={"white"} fontSize={28} />
          <Text color={"white"} fontWeight={"bold"} fontSize={20} pt={2} pb={2}>Escritorio</Text></HStack>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} color={"white"} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} path={link.path}>
          {link.disabled ? "Muy pronto..." : link.name}
        </NavItem>
      ))}
    </Box>
  );
};

//@ts-ignore
const NavItem = ({ icon, children, path, ...rest }) => {

  return (
    <Link
      href={path}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={{ base: 'white', md: 'black' }}
        fontWeight={500}
        _hover={{
          bg: "muni.celeste",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
//@ts-ignore
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {

  const dispatch: AppDispatch = useDispatch();
  //@ts-ignore
  const user = useSelector((state) => state.profile);
  const getUser = async () => {
    //@ts-ignore
    dispatch(getCurrentProfile());
    /*  const getUser = await getUserInfo(localStorage.getItem("lgac"));
      dispatch(setUser(getUser.data)); */
  };

  React.useEffect(() => {
    getUser();
  }, []);
  const handleLogout = () => {
    // logout();
    //@ts-ignore
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("lgac", "null");
    localStorage.setItem("lgrf", "null");
    window.location.reload();
  };
  return (
    <>
      <Box display={{ base: "flex", md: "none" }} bg={"white"} h={16} alignItems={"center"} justifyContent="center" mx="auto">
        <MiBahiaIcon display={{ base: "flex", md: "none" }} w="30%" h="100%" />
      </Box>

      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        maxH={16}

        alignItems="center"
        bg={useColorModeValue({ base: '#4383D4', md: 'white' }, "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      ><HStack>
          <Icon as={FiHome} color={"white"} />
          <Text color={"white"} fontWeight={"bold"} fontSize={20} pt={2} pb={2}>Escritorio</Text></HStack>
        <Icon
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          color={"white"}
          as={FiMenu}
        />
        <HStack display={{ base: "none", md: "flex" }} spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack> <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-end"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" fontWeight={800}>
                    {user?.user?.first_name}{" "}
                    {user?.user?.last_name}
                  </Text>
                  <Tag size={"sm"} variant='solid' bg='muni.verde'>
                    Nivel 2
                  </Tag>
                </VStack>
                  <Avatar
                    size={"sm"}
                    src={
                      "https://posturas.com.ar/download/multimedia.normal.a4cd505fa4ac2558.6465207765657274685f6e6f726d616c2e6a7067.jpg"
                    }
                  />

                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue("white", "gray.900")}
                borderColor={useColorModeValue("gray.200", "gray.700")}
              >
                <MenuItem>
                  <LinkDom
                    to="/perfil">Editar perfil</LinkDom>
                </MenuItem>
                <MenuItem onClick={handleLogout}>Cerrar sesion</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    </>
  );
};
