import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Account } from "../screens/Account";
import { ForgotPassword } from "../screens/auth/ForgotPassword";
import { LoginByAfip } from "../screens/auth/LoginByAfip";
import { LoginForm } from "../screens/auth/LoginForm";
import { RegisterForm } from "../screens/auth/RegisterForm";
import { ProfileForm } from "../screens/dashboard/ProfileForm";
import { SidebarWithHeader } from "../screens/dashboard/SidebarWithHeader";
import { NotFound } from "../utilities/NotFound";
import LoggedInRoutes from "./loggedInRoutes";
import LoggedOutRoutes from "./loggedOutRoutes";
import { EditProfile } from "../screens/EditProfile";
import { ResendVerification } from "../screens/auth/ResendVerification";
import { LoginFormToken } from "../screens/auth/LoginFormToken";
import { Home } from "../screens/Home";
import { LoginByAnses } from "../screens/auth/LoginByAnses";
import { LoginByMiArgentina } from "../screens/auth/LoginByMiArgentina";
import { LoginByRenaper } from "../screens/auth/LoginByRenaper";
import { Reclamos } from "../screens/Reclamos";
import { HomeInicial } from "../screens/HomeInicial";

export const RoutesApp = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route element={<LoggedOutRoutes />}>
          <Route element={<LoginForm />} path="/" />
          <Route element={<LoginForm />} path="/login/" />
          <Route element={<RegisterForm />} path="/registrarse" />
          <Route element={<LoginByAfip />} path="/afipredirect/*" />
          <Route element={<LoginByAnses />} path="/ansesredirect/*" />
          <Route element={<LoginByMiArgentina />} path="/miargentinaredirect/*" />
          <Route element={<LoginByRenaper />} path="/renaperredirect/*" />

          <Route element={<ForgotPassword />} path="/forgotpassword" />
          <Route
            element={<ResendVerification />}
            path="/ResendVerification"
          ></Route>
        </Route>
        <Route element={<LoginFormToken />} path="/login/:token" />

        <Route path="*" element={<NotFound />} />
        <Route path="/notfound" element={<NotFound />} />

        <Route element={<LoggedInRoutes />}>
          <Route element={<ProfileForm />} path="/profileform" />
          <Route element={<HomeInicial />} path="/dashboard" />
          <Route element={<Reclamos />} path="/reclamos" />

          <Route element={<EditProfile />} path="/perfil" />
          {/* <Route element={<ChangePassword />} path="/renovarcredenciales" /> */}
        </Route>
        {/* 
          
          <Route
            path="/recoverpassword/:token"
            element={<RecoverPasswordForm />}
          />
                     <Route path="/activateaccount/:token" element={<ActivateAccount />} />
*/}
      </Routes>
    </BrowserRouter>
  </>
);
