import { useState } from "react";
import React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Image,
  Heading,
  Collapse,
  FormControl,
  Stack,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  HStack,
  Divider,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../../../ColorModeSwitcher";
import { Logo } from "../../../Logo";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AfipIcon, AutenticaIcon } from "../../../components/Icons";
import { useNavigate } from "react-router-dom";
import Form from "../../../utilities/Forms";

export const ResendVerificationForm = (setEmailSentScreenVisible: any) => {
  const titleColor = useColorModeValue("muni.celeste", "teal.200");

  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSendData = async () => {
    const formData = new FormData();
    formData.append("email", email);
    //const response = await forgotPassword(formData);

    //setEmailSentScreenVisible(true);
  };
  return (
    <>
      <Text
        mb="10px"
        ms="4px"
        mt="0px"
        color={"blackAlpha.700"}
        fontWeight={600}
        fontSize={{ base: "15px", md: "20px", lg: "20px" }}
      >
        ¿validar tu cuenta?
      </Text>
      <Text
        fontSize={{ base: "sm", sm: "md" }}
        color={useColorModeValue("gray.800", "gray.400")}
      >
        Ingresá el mail asociado a tu cuenta y vas a recibir instrucciones para
        validarla.
      </Text>
      <FormControl id="email">
        <Input
          placeholder="tu-email@ejemplo.com"
          _placeholder={{ color: "gray.500" }}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <Stack spacing={6}>
        <Button
          fontSize="12px"
          type="submit"
          bg="muni.celeste"
          w="100%"
          h="45"
          mb="20px"
          color="white"
          _hover={{
            bg: "muni.verde",
          }}
          _active={{
            bg: "muni.verde",
          }}
          onClick={() => handleSendData()}
        >
          ENVIAR
        </Button>
        <Link
          onClick={() => navigate("/login/")}
          color={titleColor}
          fontWeight="bold"
        >
          Volver a inicio
        </Link>
      </Stack>
    </>
  );
};
