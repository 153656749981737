import React, { useRef, useState } from "react";
import {
  Box,
  Text,
  Link,
  Flex,
  Image,
  Heading,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  HStack,
  useColorModeValue,
  useDisclosure,
  useToast,
  NumberInputField,
  NumberInput,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AutenticaIcon } from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { axiosLoggedOutConfig } from "../../utilities/Axios";
import { AppDispatch } from "../../store/StoreRoot";
import { useDispatch, useSelector } from "react-redux";
import { registerPost } from "./_duck/registerSlice";
import { LoadSpinner } from "../../components/Spinner";

export const RegisterForm = () => {
  const { isOpen, onClose } = useDisclosure();
  const dispatch: AppDispatch = useDispatch();
  const {
    isOpen: isOpenPolitica,
    onOpen: onOpenPolitica,
    onClose: onClosePolitica,
  } = useDisclosure();
  const [usuario, setUsuario] = useState({
    nombre: "",
    apellido: "",
    dni: 0,
    email: "",
    password: "",
  });

  const [passConfirmation, setPassConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [invalidDNI, setinvalidDNI] = useState(false);
  const [invalidPass, setinvalidPass] = useState(false);
  const [enableFields, setenableField] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const { loading, error: hasError } = useSelector(
    (resp: any) => resp.register
  );

  const toastIdRef = useRef();

  const handleRegistro = () => {
    if (passConfirmation !== usuario.password) {
      setinvalidPass(true);
      return;
    }
    setinvalidPass(false);
    sendUserToAPI();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const sendUserToAPI = async () => {
    const validate = validateRegister();

    if (validate) {
      const formData = new FormData();
      formData.append("first_name", usuario["nombre"]);
      formData.append("last_name", usuario["apellido"]);
      //@ts-ignore
      formData.append("dni", usuario["dni"]);
      formData.append("email", usuario["email"]);
      formData.append("password", usuario["password"]);
      await dispatch(registerPost(formData))
        .unwrap()
        .then(async (resp) => {
          navigate("/login");
        });
    }
  };

  //@ts-ignore
  const checkDni = async (dni) => {
    setUsuario({ ...usuario, dni: dni });
    if (dni.length >= 8) {
      try {
        const llamada = await axiosLoggedOutConfig.post("/checkuser/", {
          dni: dni,
        });
        setenableField(false);
        setinvalidDNI(false);
      } catch {
        setinvalidDNI(true);
        setenableField(true);
      }
    }
  };

  const validateRegister = () => {
    let isValid = true;

    if (
      usuario.nombre === "" ||
      usuario.nombre.length < 3 ||
      usuario.nombre.length > 50
    ) {
      isValid = false;
      messageError("El nombre debe tener entre 3 y 50 caracteres");
      return;
    }
    if (usuario.apellido === "") {
      isValid = false;
      messageError("Debe ingresar un apellido");
      return;
    }
    if (validateDni(usuario.dni) === false) {
      isValid = false;
      messageError("El numero de documento no es valido");
      return;
    }
    if (validateEmail(usuario.email) === false) {
      isValid = false;
      messageError("El email no es valido");
      return;
    }
    if (usuario.password.length < 6 || usuario.password.length > 20) {
      isValid = false;
      messageError("La contraseña debe tener entre 6 y 20 caracteres");
      return;
    }

    return isValid;
  };

  //@ts-ignore
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  //@ts-ignore
  const validateDni = (dni: any) => {
    const re = /^[0-9]{8}$/;
    return re.test(String(dni));
  };
  //@ts-ignore
  const messageError = (message) => {
    //@ts-ignore
    if (!toast.isActive(toastIdRef.current))
      //@ts-ignore
      toastIdRef.current = toast({
        title: "Error",
        position: "bottom-left",
        description: message,
        status: "error",
        isClosable: true,
      });
  };

  // Chakra color mode
  const titleColor = useColorModeValue("muni.verde", "white");
  const textColor = useColorModeValue("gray.400", "white");

  return (
    <>

      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>

        <Stack flex={1} bg={"muni.celeste"} justify={"center"} spacing={10} direction={"column"} display={{ base: 'none', md: 'flex' }} align={"center"}>
          <Image
            src={
              "/slider.png"
            }

          />
          <Text fontSize='3xl' fontWeight={'bold'} style={{ color: ' white', }} width={"40%"} textAlign={"center"}>Registrate y validá tus
            datos por única vez</Text>
        </Stack>
        <Flex flex={1} align={'center'} justify={'center'} bg={"#f5f6fa"}>
          <Flex
            h={{ base: "100vh", md: "100vh", lg: "90vh" }}
            w="100%"
            maxW="1044px"
            mx="auto"
            justifyContent="center"
            pt={{ sm: "10px", md: "0px" }}
          >
            <Flex
              alignItems="center"
              justifyContent="start"
              style={{ userSelect: "none" }}
              w={{ base: "100%", md: "80%", lg: "50%" }}

            >
              <Flex
                direction="column"
                w="100%"
                background="transparent"
                p="20px"
                mt={{ md: "0px", lg: "0px" }}
              >
                <Heading color={titleColor} fontSize="48px">
                  <Box alignItems={"center"}>
                    <AutenticaIcon w="100%" h="100%" />
                  </Box>
                </Heading>
                <Text
                  mb="36px"
                  ms="4px"
                  color={useColorModeValue("blackAlpha.700", "white")}
                  fontWeight={600}
                  fontSize={{ base: "15px", md: "20px", lg: "20px" }}
                >
                  Completá tus datos
                </Text>
                <FormControl isInvalid={invalidDNI} mb="24px">
                  <NumberInput
                    isRequired
                    id="dni"
                    fontSize="sm"
                    ms="4px"
                    bg={"white"}
                    borderRadius="5px"
                    size="lg"
                    onChange={(e) => checkDni(e)}
                  >
                    <NumberInputField placeholder={"Número de documento (DNI)"}
                    />
                  </NumberInput>
                  {true && (
                    <FormErrorMessage>
                      El documento ya esta registrado, <b>inicia sesion</b>
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl>
                  <Input
                    id="nombre"
                    fontSize="sm"
                    ms="4px"
                    isDisabled={enableFields}
                    borderRadius="5px"
                    placeholder="Tu Nombre"
                    bg={"white"}
                    mb="24px"
                    size="lg"
                    onChange={(e) => {
                      setUsuario({ ...usuario, nombre: e.target.value });
                    }}
                  />

                  <Input
                    id="apellido"
                    fontSize="sm"
                    ms="4px"
                    borderRadius="5px"
                    isDisabled={enableFields}
                    bg={"white"}
                    type="text"
                    placeholder="Apellido"
                    mb="24px"
                    size="lg"
                    onChange={(e) =>
                      setUsuario({ ...usuario, apellido: e.target.value })
                    }
                  />
                  <Input
                    id="email"
                    fontSize="sm"
                    ms="4px"
                    borderRadius="5px"
                    isDisabled={enableFields}
                    bg={"white"}
                    type="email"
                    placeholder="Tu e-mail"
                    mb="24px"
                    size="lg"
                    onChange={(e) =>
                      setUsuario({ ...usuario, email: e.target.value })
                    }
                  />
                  <FormControl isInvalid={invalidPass}>
                    <InputGroup size="md">
                      <Input
                        id="password"
                        fontSize="sm"
                        ms="4px"
                        borderRadius="5px"
                        bg={"white"}
                        isDisabled={enableFields}
                        type={showPassword ? "text" : "password"}
                        placeholder="Contraseña"
                        mb="24px"
                        size="lg"
                        onChange={(e) =>
                          setUsuario({ ...usuario, password: e.target.value })
                        }
                      />
                      <InputRightElement width="4.5rem">
                        <Flex onClick={handleShowPassword}>
                          {showPassword ? (
                            <ViewIcon
                              w={6}
                              h={6}
                              _hover={{ cursor: "pointer" }}
                              mt="25%"
                              ml="25%"
                            />
                          ) : (
                            <ViewOffIcon
                              w={6}
                              h={6}
                              _hover={{ cursor: "pointer" }}
                              mt="25%"
                              ml="25%"
                            />
                          )}
                        </Flex>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl isInvalid={invalidPass}>
                    <InputGroup size="md">
                      <Input
                        fontSize="sm"
                        ms="4px"
                        borderRadius="5px"
                        bg={"white"}
                        mb="24px"
                        size="lg"
                        isDisabled={enableFields}
                        type={showPasswordConfirmation ? "text" : "password"}
                        placeholder="Confirmar contraseña"
                        required
                        onChange={(e) => setPassConfirmation(e.target.value)}
                      />
                      <InputRightElement width="4.5rem" overflow="hidden">
                        <Flex onClick={handleShowPasswordConfirmation}>
                          {showPasswordConfirmation ? (
                            <ViewIcon
                              w={6}
                              h={6}
                              _hover={{ cursor: "pointer" }}
                              mt="25%"
                              ml="25%"
                            />
                          ) : (
                            <ViewOffIcon
                              w={6}
                              h={6}
                              _hover={{ cursor: "pointer" }}
                              mt="25%"
                              ml="25%"
                            />
                          )}
                        </Flex>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  {invalidPass && (
                    <Text color={"red.500"} mt={-4} mb={4}>
                      Las contraseñas <b>no coinciden.</b>
                    </Text>
                  )}
                  <Button
                    fontSize="10px"
                    type="submit"
                    bg="muni.celeste"
                    w="100%"
                    h="45"
                    p={6}

                    mb="20px"
                    color="white"
                    _hover={{
                      bg: "muni.verde",
                    }}
                    _active={{
                      bg: "muni.verde",
                    }}
                    onClick={handleRegistro}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Text fontWeight={"bold"} fontSize={"18px"}>
                        REGISTRARME
                      </Text>
                    )}
                  </Button>

                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Se ha registrado con éxito</ModalHeader>
                      <ModalBody>
                        <p>
                          Se ha enviado un mail a su casilla de correo
                          electrónico. Siga las instrucciones para activar la
                          cuenta.
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          bg="muni.celeste"
                          fontSize="10px"
                          color="white"
                          fontWeight="bold"
                          w="100%"
                          h="45"
                          mb="24px"
                          onClick={() => navigate("/login")}
                          _hover={{
                            bg: "teal.200",
                          }}
                          _active={{
                            bg: "teal.400",
                          }}
                        >
                          <Text fontWeight={"bold"} fontSize={"13px"}>
                            SIGUIENTE
                          </Text>
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </FormControl>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  maxW="100%"
                  mt="0px"
                >
                  <Text color={textColor} fontWeight="medium">
                    Ya tienes una cuenta?
                    <Link
                      color={titleColor}
                      ms="5px"
                      onClick={() => navigate("/login/")}
                      fontWeight="bold"
                    >
                      Inicia Sesion
                    </Link>
                  </Text>
                </Flex>

                <FormControl
                  alignItems="center"
                  justifyContent="space-between"
                  alignContent={"center"}
                  textAlign={"center"}
                  alignSelf={"center"}
                >
                  <Link
                    color={"gray.500"}
                    fontWeight="bold"
                    onClick={() => onOpenPolitica()}
                  >
                    Politica de privacidad de datos
                  </Link>
                </FormControl>
              </Flex>

            </Flex>

          </Flex>
        </Flex>
        <Box>
          <Drawer placement={"bottom"} onClose={onClosePolitica} isOpen={isOpenPolitica}>
            <DrawerOverlay />
            <DrawerContent >
              <DrawerHeader borderBottomWidth='1px' color={"muni.celeste"}>Politica de Privacidad de Datos</DrawerHeader>
              <DrawerBody>
                <p>Fecha de última actualización: 3 de agosto de 2023</p>
                <p>El sitio web "autentica.bahia" es gestionado por el Gobierno de Bahía Blanca. Nos preocupamos por la privacidad y seguridad de la información personal de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos, compartimos y protegemos la información personal que nos proporciona al utilizar nuestro sistema de logueo centralizado.</p>

                <h2>1. Recopilación de información personal:</h2>
                <p>Para utilizar los servicios ofrecidos en "autentica.bahia", es necesario registrarse y proporcionar cierta información personal, que puede incluir su nombre, dirección de correo electrónico y contraseña cifrada. Esta información es necesaria para autenticar y autorizar el acceso a los servicios gubernamentales en línea.</p>

                <h2>2. Uso de la información personal:</h2>
                <p>La información personal recopilada se utilizará exclusivamente para fines del sistema de logueo centralizado. Su objetivo principal es facilitar un acceso seguro y unificado a los servicios gubernamentales ofrecidos en línea, minimizando la necesidad de múltiples credenciales.</p>

                <h2>3. Protección de la información personal:</h2>
                <p>Hemos implementado medidas de seguridad adecuadas para proteger la información personal de los usuarios contra pérdidas, usos no autorizados o accesos indebidos. El acceso a los datos se limita solo a personal autorizado que requiere esta información para el correcto funcionamiento del sistema.</p>

                <h2>4. Compartir información personal:</h2>
                <p>La información personal recopilada no será compartida, vendida o transferida a terceros, excepto cuando sea necesario para cumplir con obligaciones legales o regulaciones gubernamentales. La información podrá ser compartida con otras entidades gubernamentales bajo los mismos estándares de protección de privacidad.</p>

                <h2>5. Retención de datos:</h2>
                <p>Los datos personales se retendrán únicamente durante el tiempo necesario para cumplir con los fines para los que fueron recopilados, o el tiempo requerido por la ley.</p>

                <h2>6. Derechos de los usuarios:</h2>
                <p>Los usuarios tienen derecho a acceder, corregir y eliminar su información personal. Si desean ejercer estos derechos o tienen alguna inquietud sobre la privacidad de sus datos, pueden comunicarse con el departamento de soporte del sitio autentica.bahia.</p>

                <h2>7. Enlaces a sitios externos:</h2>
                <p>El sitio web autentica.bahia puede contener enlaces a sitios web de terceros. Esta Política de Privacidad se aplica únicamente al sitio autentica.bahia. Se recomienda a los usuarios revisar las políticas de privacidad de los sitios externos antes de proporcionar información personal.</p>

                <h2>8. Cambios en la Política de Privacidad:</h2>
                <p>Esta política puede ser modificada en cualquier momento por el Gobierno de Bahía Blanca. En caso de cambios significativos, se notificará a los usuarios a través de un aviso en el sitio web.</p>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Stack >
    </>
  );
};
