import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  extendTheme,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { Home } from "./screens/Home";
import { Provider } from "react-redux";
import { store } from "./store/StoreRoot";
import { RoutesApp } from "./routes";
import "@fontsource/kanit"; // Defaults to weight 400
import "@fontsource/kanit/100.css"; // Specify weight

import "@fontsource/kanit/200.css"; // Specify weight

import "@fontsource/kanit/300.css"; // Specify weight

import "@fontsource/kanit/400.css"; // Specify weight
import "@fontsource/kanit/600.css"; // Specify weight

import "@fontsource/kanit/500.css"; // Specify weight

import "@fontsource/kanit/600.css"; // Specify weight

import "@fontsource/kanit/700.css"; // Specify weight



const theme = extendTheme({
  fonts: {
    heading: `'Kanit', sans-serif`,
    body: `'Kanit', sans-serif`,
  },
  colors: {
    muni: {
      verde: "#37BBED",
      celeste: "#020659",
    },
    afip: {
      600: "#020659",
    },
    verde: {
      500: "#95c840"
    }
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <RoutesApp />
    </Provider>
  </ChakraProvider>
);
