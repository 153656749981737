import * as React from "react";
import {
  Box,
  Text,
  VStack,
  Grid,
  SkeletonText,
  Skeleton,
  useColorModeValue,
  SimpleGrid,
  HStack,
  Stack,
  Card,
  CardBody,
  Divider,
  Icon,
  Button,
  Progress,
  Image,
  Center,
  Heading,
  Flex,
  Input,
  Select,
} from "@chakra-ui/react";
import { AutenticaIcon, ReclamosIcon } from "../components/Icons";
import { FiBook, FiCreditCard, FiDownload, FiFileText, FiFolder, FiMail, FiPlus, FiSearch, FiSettings, FiUser } from "react-icons/fi";
import { SidebarWithHeader } from "./dashboard/SidebarWithHeader";
import { QuestionIcon } from "@chakra-ui/icons";
import { AppDispatch } from "../store/StoreRoot";
import { useDispatch, useSelector } from "react-redux";
import { getReclamos, getVencimientoLicencia } from "./auth/_duck/homeSlice";
import moment from "moment";

export const Reclamos = () => {

  const dispatch: AppDispatch = useDispatch();
  //@ts-ignore
  const user = useSelector((state) => state.profile);
  //@ts-ignore
  const { licencia, reclamos } = useSelector((state) => state.home);

  const getUser = async () => {
    //@ts-ignore
    dispatch(getCurrentProfile());
    /*  const getUser = await getUserInfo(localStorage.getItem("lgac"));
      dispatch(setUser(getUser.data)); */
  };

  React.useEffect(() => {
    dispatch(getVencimientoLicencia())
    dispatch(getReclamos());

    getUser();
  }, []);
  const color = useColorModeValue("white", "gray.700");
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const [reclamosEnRango, setReclamosEnRango] = React.useState([]);

  React.useEffect(() => {
    //@ts-ignore
    const reclamosFiltrados = reclamos.filter((reclamo) => {
      const fechaReclamo = new Date(reclamo.formatted_fecha);
      const fechaActual = new Date();
      const fechaLimiteSuperior = new Date();
      const fechaLimiteInferior = new Date();

      fechaLimiteSuperior.setDate(fechaActual.getDate() + 10);
      fechaLimiteInferior.setDate(fechaActual.getDate() - 10);

      return fechaReclamo >= fechaLimiteInferior && fechaReclamo <= fechaLimiteSuperior;
    });

    // Ordenar los reclamos por fecha, de la más cercana a la más lejana
    //@ts-ignore
    reclamosFiltrados.sort((a, b) => {
      const fechaA = new Date(a.formatted_fecha);
      const fechaB = new Date(b.formatted_fecha);
      //@ts-ignore
      return fechaA - fechaB;
    });

    setReclamosEnRango(reclamosFiltrados);
  }, [reclamos]);


  return (
    <>
      <SidebarWithHeader>
        <Box
          textAlign="center"
          fontSize="xl"
          w={"100%"}
          bg={useColorModeValue("gray.100", "gray.900")}
        >
          <Grid p={3}>
            <VStack spacing={0}>

              <Stack
                direction={{ base: "column", md: "row" }}
                justifyContent={"space-between"}
                mb={4}
                w={"95%"}
              > <Box>
                  <Heading>
                    <Icon as={ReclamosIcon} mr={4} color={"muni.celeste"} />
                    Mis reclamos
                  </Heading>
                </Box>
                <Stack direction={"row"} spacing={4}>

                  <Select
                    bg={"white"}
                    size={"md"}
                  >
                    <option value="">Filtrar por categoria</option>
                    <option value="insumo">insumo</option>
                    <option value="operacionales">operacionales</option>
                  </Select>
                  <Select
                    bg={"white"}
                    size={"md"}
                  >
                    <option value="">Filtrar por estado</option>
                    <option value="insumo">insumo</option>
                    <option value="operacionales">operacionales</option>
                  </Select>
                  <Select
                    bg={"white"}
                    size={"md"}
                  >
                    <option value="">1</option>
                    <option value="insumo">insumo</option>
                    <option value="operacionales">operacionales</option>
                  </Select>
                </Stack>

              </Stack>
              <Card minH="83px" mt={4} boxShadow={"none"} borderRadius={"2xl"} w={"95%"}>
                <CardBody display="flex" flexDirection="column">

                  {//@ts-ignore
                    reclamos.map((reclamo, index) => (
                      <div key={index}>
                        <Box flex='1' paddingTop={4} paddingBottom={4}>
                          <HStack spacing={{ base: 5, md: 10 }} justify={"space-between"}>
                            <Box style={{ flex: 1 }}>
                              <Text color={//@ts-ignore
                                reclamosEnRango.includes(reclamo) ? "muni.celeste" : "gray.500"} fontWeight={500} textAlign={"left"} fontSize={{ base: 'lg', md: 'xl' }}>
                                {reclamo.categoria} - {reclamo.subcategoria}
                              </Text>
                            </Box>
                            <Box display={{ base: 'none', md: 'block' }}>
                              <Text fontSize={{ base: 'lg', md: 'xl' }}>{reclamo.formatted_fecha}</Text>
                            </Box>
                            <Box>
                              <Icon as={FiSearch} fontSize={30} color={//@ts-ignore
                                reclamosEnRango.includes(reclamo) ? "muni.celeste" : "gray.500"} mr={2} />
                            </Box>
                          </HStack>
                        </Box>
                        <Divider />
                      </div>
                    ))}

                </CardBody>
              </Card>

            </VStack>
          </Grid>
        </Box >
      </SidebarWithHeader >
    </>
  );
};
