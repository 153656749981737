import { Outlet, Navigate } from 'react-router-dom'
import { SidebarWithHeader } from '../screens/dashboard/SidebarWithHeader';
import { Home } from '../screens/Home';
import { HomeInicial } from '../screens/HomeInicial';
const checkLogin = () => {
    return localStorage.getItem("isLoggedIn") === "true";
};


const LoggedOutRoutes = () => {
    return (
        !checkLogin() ? <Outlet /> : <HomeInicial />
    )
}

export default LoggedOutRoutes