import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { Image, UserResponse } from "../../interfaces/Use-interface";
//import { AxiosRequest } from "../../../helpers/axiosInstance";
//import { Profile } from "../../interfaces/data-interface";
import {
  axiosLoggedInConfig,
  axiosLoggedOutConfig,
} from "../../../utilities/Axios";

export const registerPost = createAsyncThunk(
  "user/loginForm",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axiosLoggedOutConfig.post(
        "customuser/",
        data
      );
      //@ts-ignore
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/* 
export const changePass = createAsyncThunk(
  "user/changePassword",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await AxiosRequest.put("/api/security/change/password", data);
      //@ts-ignore
      return response.data;
    } catch (error: any) {
      console.log(error.response.data, "falle aqui");
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeProfileImage = createAsyncThunk(
  "user/changeProfileImage",
  async (data: any, { rejectWithValue }) => {
    try {
      const image = `data:image/jpg;base64,${data.base64}`;
      console.log(image);
      const response: any = await AxiosRequest.post("/api/user/profile_picture", { file: image });
      console.log('funciono');
      //@ts-ignore
      return response.data;
    } catch (error: any) {
      console.log(error, "falle aqui");
      return rejectWithValue(error.response.data);
    }
  }
); */

interface Pros {
  loading: boolean;
  confirmed: boolean;
  error: {
    status?: number;
    message: string;
    isPinError?: boolean;
  };
}

const initialState: Pros = {
  loading: false,
  confirmed: false,
  error: {
    status: 0,
    message: "",
    isPinError: false,
  },
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    resetState() {
      return {
        ...initialState,
        confirmed: false,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(registerPost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerPost.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.confirmed = true;
    });
    builder.addCase(registerPost.rejected, (state, action: any) => {
      state.loading = false;
      state.error = {
        status: action.payload.response.status,
        message: action.payload.response.data.detail,
      };
    });
  },
});
export const { resetState } = registerSlice.actions;
export default registerSlice.reducer;
