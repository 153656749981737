import * as React from "react";
import {
  Box,
  Text,
  VStack,
  Grid,
  SkeletonText,
  Skeleton,
  useColorModeValue,
  SimpleGrid,
  HStack,
  Stack,
  Card,
  CardBody,
  Divider,
  Icon,
  Button,
  Progress,
  Image,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { AutenticaIcon } from "../components/Icons";
import { FiBook, FiCreditCard, FiDownload, FiFileText, FiFolder, FiMail, FiSearch, FiSettings } from "react-icons/fi";
import { SidebarWithHeader } from "./dashboard/SidebarWithHeader";
import { QuestionIcon } from "@chakra-ui/icons";
import { AppDispatch } from "../store/StoreRoot";
import { useDispatch, useSelector } from "react-redux";
import { getReclamos, getVencimientoLicencia } from "./auth/_duck/homeSlice";
import moment from "moment";
import { getCurrentProfile } from "./auth/_duck";

export const HomeInicial = () => {

  const dispatch: AppDispatch = useDispatch();
  //@ts-ignore
  const user = useSelector((state) => state.profile);
  //@ts-ignore

  const getUser = async () => {
    //@ts-ignore
    dispatch(getCurrentProfile())
      .unwrap()
      .then(async (resplogin) => {
        window.location.href = `https://mibahia.gob.ar/login/${resplogin.alpha}`;

      }).catch(() => {
        //@ts-ignore
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("lgac", "null");
        localStorage.setItem("lgrf", "null");
        window.location.reload();
      })
    /*  const getUser = await getUserInfo(localStorage.getItem("lgac"));
      dispatch(setUser(getUser.data)); */
  };

  React.useEffect(() => {

    getUser();
  }, []);


  return (
    <>
      <Center height="100vh">
        <Spinner
          thickness='6px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />      </Center>
    </>
  );
};
