import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Text,
  useToast,
  Select,
  Heading,
  useColorModeValue,
  Stack,
  Image,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { useState, useRef, useEffect } from "react";
import { json, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNominatim } from "../../utilities/UseNominatim";
import { AutenticaIcon } from "../../components/Icons";
import { Select as SelectChakra, OptionBase } from "chakra-react-select";

import { getLocations } from "../auth/_duck";

export const ProfileForm = () => {
  const toast = useToast();
  const toastIdRef = useRef();
  const dispatch = useDispatch();
  const titleColor = useColorModeValue("muni.celeste", "teal.200");

  const { coordenadas, obtenerCoordenadas } = useNominatim();

  //@ts-ignore
  const { user } = useSelector((state) => state.profile);
  useEffect(() => {
    //@ts-ignore
    dispatch(getLocations());
  }, []);
  //@ts-ignore
  const { profile, locations } = useSelector((state) => state.profile);

  const [perfil, setPerfil] = useState({
    id: user?.id_custom,
    user_profile_id: user?.id_profile,
    first_name: user?.first_name,
    last_name: user?.last_name,
    dni: user?.dni,
    email: user?.email,

    gender: profile?.gender,
    birth_date: profile?.birth_date,
    town: profile?.town,
    postal_code: profile?.postal_code,
    apartment: profile?.apartment,
    adress_name: profile?.street,
    adress_height: profile?.height,
    adress_floor: profile?.floor,

    phone_prefix: profile?.phone_prefix,
    phone_number: profile?.phone,
  });

  perfil.id = user?.id_custom;
  perfil.user_profile_id = user?.id_profile;

  if (perfil.first_name == undefined) {
    perfil.first_name = user?.first_name;
  }
  if (perfil.last_name == undefined) {
    perfil.last_name = user?.last_name;
  }
  if (perfil.dni == undefined) {
    perfil.dni = user?.dni;
  }
  if (perfil.email == undefined) {
    if (user?.email.includes("bbtst")) {
      perfil.email = "";
    } else {
      perfil.email = user?.email;
    }
  }
  if (perfil.birth_date == null) {
    perfil.birth_date = profile?.birth_date;
  }
  if (perfil.gender == null) {
    perfil.gender = profile?.gender;
  }
  if (perfil.adress_name == null) {
    perfil.adress_name = profile?.street;
  }
  if (perfil.adress_floor == null) {
    perfil.adress_floor = profile?.floor;
  }
  if (perfil.adress_height == null) {
    perfil.adress_height = profile?.height;
  }
  if (perfil.apartment == null) {
    perfil.apartment = profile?.apartment;
  }
  if (perfil.town == null) {
    perfil.town = profile?.town;
  }
  if (perfil.postal_code == null) {
    perfil.postal_code = profile?.postal_code;
  }
  if (perfil.phone_number == null) {
    perfil.phone_number = profile?.phone;
  }
  if (perfil.phone_prefix == null) {
    perfil.phone_prefix = profile?.phone_prefix;
  }

  const navigate = useNavigate();

  const sendProfileToAPI = async () => {
    const validate = validateRegister();

    if (validate) {
      const formData = new FormData();

      const obtenerLatLong = await obtenerCoordenadas(
        perfil.adress_name,
        perfil.adress_height
      );

      formData.append("user_profile_id", perfil["user_profile_id"]);
      formData.append("gender", perfil["gender"]);
      formData.append("birth_date", perfil["birth_date"]);
      formData.append("town", perfil["town"]);
      formData.append("postal_code", perfil["postal_code"]);
      formData.append("street", perfil["adress_name"]);
      formData.append("height", perfil["adress_height"]);
      formData.append("apartment", perfil["apartment"]);
      formData.append("floor", perfil["adress_floor"]);
      formData.append("phone_prefix", perfil["phone_prefix"]);
      formData.append("phone", perfil["phone_number"]);
      //@ts-ignore
      formData.append("viewed", true);
      formData.append("long", obtenerLatLong[0].lon);
      formData.append("lat", obtenerLatLong[0].lat);
      //@ts-ignore
      const response = await patchProfile(formData, perfil.user_profile_id);

      const formPatchUser = new FormData();
      formPatchUser.append("id", perfil["id"]);
      formPatchUser.append("first_name", perfil["first_name"]);
      formPatchUser.append("last_name", perfil["last_name"]);
      formPatchUser.append("email", perfil["email"]);
      formPatchUser.append("dni", perfil["dni"]);
      //@ts-ignore
      const patch_user = await patchUser(formPatchUser, perfil.id);

      if (patch_user.response == undefined) {
        navigate("/");
      } else if (patch_user.response.status === 400) {
        if (patch_user.response.data.email) {
          messageError("El email no es valido");
        } else if (patch_user.response.data.dni) {
          messageError("El DNI no es valido");
        } else {
          navigate("/dashboard");
        }
      }
    }
  };

  const validateRegister = () => {
    let isValid = true;

    if (perfil.first_name === "") {
      isValid = false;
      messageError("El nombre debe tener entre 3 y 50 caracteres");
      return;
    }
    if (perfil.last_name === "") {
      isValid = false;
      messageError("Debe ingresar un apellido");
      return;
    }
    if (perfil.gender === undefined) {
      isValid = false;
      messageError("Debe elegir sexo");
      return;
    }

    // if (validateDni(perfil.dni) === false) {
    //     isValid = false;
    //     messageError("El numero de documento no es valido");
    //     return

    if (validateEmail(perfil.email) === false) {
      isValid = false;
      messageError("El email no es valido");
      return;
    }
    if (perfil.birth_date === undefined) {
      isValid = false;
      messageError("Debe introducir una fecha válida");
      return;
    }
    if (perfil.town === undefined) {
      isValid = false;
      messageError("Por favor seleccione una localidad");
      return;
    }
    if (perfil.postal_code === undefined) {
      isValid = false;
      messageError("Por favor seleccione el código postal");
      return;
    }
    if (perfil.adress_name === undefined) {
      isValid = false;
      messageError("Por favor seleccione una calle");
      return;
    }
    if (perfil.adress_height === undefined) {
      isValid = false;
      messageError("Por favor seleccione la altura de la calle");
      return;
    }
    if (perfil.phone_prefix === undefined) {
      isValid = false;
      messageError("Por favor ingrese el prefijo de teléfono");
      return;
    }
    if (perfil.phone_number === undefined) {
      isValid = false;
      messageError("Por favor un número de teléfono");
      return;
    }
    return isValid;
  };
  //@ts-ignore
  const messageError = (message) => {
    //@ts-ignore
    if (!toast.isActive(toastIdRef.current))
      //@ts-ignore
      toastIdRef.current = toast({
        title: "Error",
        position: "bottom-left",
        description: message,
        status: "error",
        isClosable: true,
      });
  };
  const validateEmail = (email: any) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // const validateDni = (dni) => {
  //     const re = /^[0-9]{8}$/;
  //     return re.test(String(dni));
  // }

  return (
    <>
      <Box
        bg={useColorModeValue("white", "white")}
        px={4}
        display={{ base: "none", md: "none", lg: "block" }}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"right"}>
          <Box>
            <Image
              src={
                "https://www.bahia.gob.ar/wp-content/uploads/2018/04/municipio-de-bahia-blanca.png"
              }
              alt="chakra image"
              maxWidth={{ md: "300px", lg: "200px", base: "200px" }}
            />
          </Box>
        </Flex>
      </Box>
      <Flex position="relative">
        <Flex
          h={{ base: "auto", md: "100vh", lg: "90vh" }}
          w="100%"
          maxW="1044px"
          mx="auto"
          justifyContent="center"
          pt={{ sm: "10px", md: "0px" }}
        >
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "80%", lg: "62%" }}
          >
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="20px"
              mt={{ md: "0px", lg: "0px" }}
            >
              <Heading color={titleColor} fontSize="48px">
                <Box alignItems={"center"}>
                  <AutenticaIcon w="50%" h="100%" />
                </Box>
              </Heading>
              <Text
                mb="30px"
                ms="4px"
                mt="0px"
                color={"blackAlpha.700"}
                fontWeight={600}
                fontSize={{ base: "15px", md: "20px", lg: "20px" }}
              >
                Actualiza tus datos
              </Text>
              <FormControl>
                <Stack
                  direction={{ base: "column", md: "row", lg: "row" }}
                  justify="space-between"
                  align="center"
                  mb="20px"
                >
                  {" "}
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Nombre
                    </FormLabel>
                    <Input
                      id="first_name"
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      placeholder="Nombre"
                      defaultValue={perfil?.first_name}
                      mb="24px"
                      size="lg"
                      onChange={(e) => {
                        setPerfil({ ...perfil, first_name: e.target.value });
                      }}
                    />
                  </Box>
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Apellido
                    </FormLabel>
                    <Input
                      id="last_name"
                      defaultValue={perfil?.last_name}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      type="text"
                      placeholder="Apellido"
                      mb="24px"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, last_name: e.target.value })
                      }
                    />
                  </Box>
                </Stack>
                <Stack
                  direction={{ base: "column", md: "row", lg: "row" }}
                  justify="space-between"
                  align="center"
                  mb="20px"
                >
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      DNI
                    </FormLabel>
                    <Input
                      required
                      id="dni"
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      defaultValue={perfil?.dni}
                      placeholder="Número de documento"
                      type="text"
                      mb="24px"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, dni: e.target.value })
                      }
                    ></Input>
                  </Box>
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Sexo
                    </FormLabel>
                    <Select
                      id="gender"
                      value={perfil?.gender}
                      placeholder="Seleccione una opción"
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      mb="24px"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, gender: e.target.value })
                      }
                    >
                      <option value="M">Masculino</option>
                      <option value="F">Femenino</option>
                      <option value="O">Otro</option>
                    </Select>
                  </Box>
                </Stack>
                <Stack
                  direction={{ base: "column", md: "row", lg: "row" }}
                  justify="space-between"
                  align="center"
                  mb="20px"
                >
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Email
                    </FormLabel>
                    <Input
                      id="email"
                      fontSize="sm"
                      defaultValue={perfil?.email}
                      ms="4px"
                      borderRadius="5px"
                      type="email"
                      placeholder="Tu email"
                      mb="24px"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, email: e.target.value })
                      }
                    />
                  </Box>
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Fecha de nacimiento
                    </FormLabel>
                    <Input
                      id="birth_date"
                      defaultValue={perfil?.birth_date}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      type="date"
                      placeholder="Tu email"
                      mb="24px"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, birth_date: e.target.value })
                      }
                    />
                  </Box>
                </Stack>

                <Stack
                  direction={{ base: "column", md: "row", lg: "row" }}
                  justify="space-between"
                  align="center"
                  mb="20px"
                >
                  <Box w="100%">
                    <FormLabel mt="-20px" fontSize="sm" fontWeight="normal">
                      Ciudad de residencia
                    </FormLabel>
                    {locations.length ? (
                      <SelectChakra
                        name="ciudad"
                        size="lg"
                        options={locations}
                        placeholder="Selecciona tu ciudad..."
                        closeMenuOnSelect
                        onChange={(e) => setPerfil({ ...perfil, town: e })}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </Box>
                  <Box w="100%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Código postal
                    </FormLabel>
                    <Input
                      required
                      id="postal_code"
                      defaultValue={perfil?.postal_code}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      type="number"
                      placeholder="Tu código postal"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, postal_code: e.target.value })
                      }
                    ></Input>
                  </Box>
                </Stack>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  <h1>Dirección</h1>
                </FormLabel>
                <Stack
                  direction={{ base: "column", md: "row", lg: "row" }}
                  justify="center"
                  align="center"
                  mb="20px"
                >
                  <Stack
                    direction={{ base: "column", md: "row", lg: "row" }}
                    justify="space-between"
                    align="center"
                    w="100%"
                  >
                    <Box w="100%">
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Nombre
                      </FormLabel>
                      <Input
                        id="adress_name"
                        defaultValue={perfil?.adress_name}
                        fontSize="sm"
                        ms="4px"
                        borderRadius="5px"
                        type="text"
                        placeholder="Calle"
                        mb="24px"
                        size="lg"
                        onChange={(e) =>
                          setPerfil({ ...perfil, adress_name: e.target.value })
                        }
                        disabled={coordenadas.latitud !== null}
                      />
                    </Box>
                    <Box w="100%">
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Altura
                      </FormLabel>
                      <Input
                        id="adress_height"
                        defaultValue={perfil?.adress_height}
                        fontSize="sm"
                        ms="4px"
                        borderRadius="5px"
                        type="number"
                        placeholder="Altura"
                        mb="24px"
                        size="lg"
                        onChange={(e) =>
                          setPerfil({
                            ...perfil,
                            adress_height: e.target.value,
                          })
                        }
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ base: "row", md: "row", lg: "row" }}
                    justify="space-between"
                    align="center"
                    w="100%"
                  >
                    <Box w="100%">
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Piso
                      </FormLabel>
                      <Input
                        id="adress_floor"
                        defaultValue={perfil?.adress_floor}
                        fontSize="sm"
                        ms="4px"
                        borderRadius="5px"
                        type="number"
                        placeholder="Piso"
                        mb="24px"
                        size="lg"
                        onChange={(e) =>
                          setPerfil({ ...perfil, adress_floor: e.target.value })
                        }
                      />
                    </Box>
                    <Box w="100%">
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Departamento
                      </FormLabel>
                      <Input
                        id="apartment"
                        defaultValue={perfil?.apartment}
                        fontSize="sm"
                        ms="4px"
                        borderRadius="5px"
                        type="text"
                        placeholder="Departamento"
                        mb="24px"
                        size="lg"
                        onChange={(e) =>
                          setPerfil({ ...perfil, apartment: e.target.value })
                        }
                      />
                    </Box>
                  </Stack>
                </Stack>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Teléfono
                </FormLabel>
                <HStack w="100%" spacing={3}>
                  <Box w="50%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Prefijo
                    </FormLabel>
                    <Input
                      required
                      id="phone_prefix"
                      defaultValue={perfil?.phone_prefix}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      type="number"
                      mb="24px"
                      size="lg"
                      placeholder="Número de teléfono"
                      onChange={(e) =>
                        setPerfil({ ...perfil, phone_prefix: e.target.value })
                      }
                    ></Input>
                  </Box>

                  <Box w="50%">
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Número
                    </FormLabel>
                    <Input
                      required
                      id="phone_number"
                      defaultValue={perfil?.phone_number}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="5px"
                      type="number"
                      mb="24px"
                      placeholder="Número de teléfono"
                      size="lg"
                      onChange={(e) =>
                        setPerfil({ ...perfil, phone_number: e.target.value })
                      }
                    ></Input>
                  </Box>
                </HStack>

                <Button
                  type="submit"
                  bg="muni.celeste"
                  fontSize="10px"
                  color="white"
                  fontWeight="bold"
                  w="100%"
                  h="45"
                  mb="24px"
                  onClick={sendProfileToAPI}
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                  <Text fontWeight={"bold"} fontSize={"13px"}>
                    ACTUALIZAR
                  </Text>
                </Button>
              </FormControl>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
