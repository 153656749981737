import { useState } from "react";
import React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Image,
  Heading,
  Collapse,
  FormControl,
  Stack,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  HStack,
  Divider,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { Logo } from "../../Logo";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AfipIcon, AutenticaIcon } from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import Form from "../../utilities/Forms";
import { RecoverAccountEmailSent } from "./components/RecoverAccountEmailSent";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";

export const ForgotPassword = () => {
  // Chakra color mode
  const titleColor = useColorModeValue("muni.celeste", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");

  const [dni, setDni] = useState("");
  const [emailSentScreenVisible, setEmailSentScreenVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validate, setValidate] = useState({});
  const [loading, setLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const toastIdRef = React.useRef();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const authenticate = (e: any) => {
    onClose();
    e.preventDefault();
    setLoading(true);
    const validate = validateLogin();
    if (validate) {
      sendUser();
      setDni("");
      setPassword("");
      //@ts-ignore
    } else if (!toast.isActive(toastIdRef.current)) {
      if (dni === "" || password === "") {
        setDescriptionError("Por favor, complete los campos requeridos");
        onOpen();
      } else {
        setDescriptionError(
          "Por favor, ingrese datos validos en los campos requeridos"
        );
        onOpen();
      }
    }
  };

  const validateLogin = () => {
    let isValid = true;
    //@ts-ignore
    let validator = Form.validator({
      //@ts-ignore
      dni: {
        value: dni,
        minLength: 6,
        isRequired: true,
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      setLoading(false);
      isValid = false;
    }
    return isValid;
  };

  const sendUser = async () => {
    const formData = {};
    //@ts-ignore
    formData.dni = dni;
    //@ts-ignore
    formData.password = password;
    //@ts-ignore
    formData.email = "";
    /* try {
      const res = await login(formData);

      setLoading(false);
      if (res.status === 200) {
        console.log("Estado del logueo: " + localStorage.getItem("isLoggedIn"));

        const getProfile = await getCurrentProfile(
          localStorage.getItem("lgac")
        );

        if (getProfile.data[0].viewed == true) {
          if (localStorage.getItem("isLoggedIn")) {
            navigate("/dashboard/");
          }
        } else {
          navigate("/profileform/");
        }
      }
    } catch (error) {
      {
        setLoading(false);
        setDescriptionError("DNI o contraseña incorrectos.");
        onOpen();
      }
      setPassword("");
    } */
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isOpenHelp, onToggle: onToggleHelp } = useDisclosure();
  return (
    <>
      <Box
        bg={useColorModeValue("white", "white")}
        px={4}
        display={{ base: "none", md: "none", lg: "block" }}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"right"}>
          <Box>
            <Image
              src={
                "https://www.bahia.gob.ar/wp-content/uploads/2018/04/municipio-de-bahia-blanca.png"
              }
              alt="chakra image"
              maxWidth={{ md: "300px", lg: "200px", base: "200px" }}
            />
          </Box>
        </Flex>
      </Box>
      <Flex position="relative">
        <Flex
          h={{ base: "100vh", md: "100vh", lg: "90vh" }}
          w="100%"
          maxW="1044px"
          mx="auto"
          justifyContent="center"
          pt={{ sm: "10px", md: "0px" }}
        >
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "80%", lg: "62%" }}
          >
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="20px"
              mt={{ md: "0px", lg: "0px" }}
            >
              <Heading fontSize="48px">
                <Box alignItems={"center"}>
                  <AutenticaIcon w="50%" h="100%" />
                </Box>
              </Heading>
              <Stack>
                {emailSentScreenVisible ? (
                  <RecoverAccountEmailSent />
                ) : (
                  <ForgotPasswordForm
                    setEmailSentScreenVisible={setEmailSentScreenVisible}
                  />
                )}
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
