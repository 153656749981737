import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {

    const navigate = useNavigate()

    return (<>
        <Flex justifyContent="center" alignItems="center" textAlign="center" minHeight="100vh">
            <Box textAlign="center" py={10} px={6}>
                <Heading
                    display="inline-block"
                    as="h4"
                    size="4xl"
                    bgGradient="linear(to-r, teal.400, teal.600)"
                    backgroundClip="text">
                    404
                </Heading>
                <Text fontSize="18px" mt={3} mb={2}>
                    Ups! No encontramos la pagina.
                </Text>
                <Text color={'gray.500'} mb={6}>
                    La pagina que estas buscando no existe o ocurrio un error.
                </Text>

                <Button
                    colorScheme="teal"
                    bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                    color="white"
                    variant="solid">
                    Volver al inicio
                </Button>
            </Box>
        </Flex>
    </>)
}
