import { useState } from "react";
import React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
  Image,
  Heading,
  Collapse,
  FormControl,
  Stack,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  HStack,
  Divider,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "../../../ColorModeSwitcher"
import { Logo } from "../../../Logo"
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons"
import { AfipIcon, AutenticaIcon } from "../../../components/Icons"
import { useNavigate } from "react-router-dom";
import Form from "../../../utilities/Forms";

export const RecoverAccountEmailSent = () => {
    // Chakra color mode
  const titleColor = useColorModeValue("muni.celeste", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");

  const [dni, setDni] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validate, setValidate] = useState({});
  const [loading, setLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const toastIdRef = React.useRef();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const authenticate = (e: any) => {
    onClose();
    e.preventDefault();
    setLoading(true);
    const validate = validateLogin();
    if (validate) {
      sendUser();
      setDni("");
      setPassword("");
      //@ts-ignore
    } else if (!toast.isActive(toastIdRef.current)) {
      if (dni === "" || password === "") {
        setDescriptionError("Por favor, complete los campos requeridos");
        onOpen();
      } else {
        setDescriptionError(
          "Por favor, ingrese datos validos en los campos requeridos"
        );
        onOpen();
      }
    }
  };

  const validateLogin = () => {
    let isValid = true;
//@ts-ignore
    let validator = Form.validator({
        //@ts-ignore
      dni: {
        value: dni,
        minLength: 6,
        isRequired: true,
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      setLoading(false);
      isValid = false;
    }
    return isValid;
  };

  const sendUser = async () => {
    const formData = {};
    //@ts-ignore
    formData.dni = dni;
    //@ts-ignore
    formData.password = password;
    //@ts-ignore
    formData.email = "";
    /* try {
      const res = await login(formData);

      setLoading(false);
      if (res.status === 200) {
        console.log("Estado del logueo: " + localStorage.getItem("isLoggedIn"));

        const getProfile = await getCurrentProfile(
          localStorage.getItem("lgac")
        );

        if (getProfile.data[0].viewed == true) {
          if (localStorage.getItem("isLoggedIn")) {
            navigate("/dashboard/");
          }
        } else {
          navigate("/profileform/");
        }
      }
    } catch (error) {
      {
        setLoading(false);
        setDescriptionError("DNI o contraseña incorrectos.");
        onOpen();
      }
      setPassword("");
    } */
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Si el mail ingresado coincide con una cuenta asociada en el sitio, se han enviado a este las instrucciones para recuperar tu cuenta.
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}>
          Ingresá a tu email, seguí el enlace que te enviamos y completá los datos para cambiar la contraseña.
        </Text>
        <Stack spacing={6}>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => navigate("/login/")}>
            VOLVER A INICIO
          </Button>
        </Stack>
      </>
)}
