import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { Image, UserResponse } from "../../interfaces/Use-interface";
//import { AxiosRequest } from "../../../helpers/axiosInstance";
//import { Profile } from "../../interfaces/data-interface";


import axios from "axios";


export const getCalles = createAsyncThunk(
    "registro/calles",
    async (_, { rejectWithValue }) => {
        try {
            const response: any = await axios.get(
                "https://api.mibahia.gob.ar/calles/calles/"
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);


interface Pros {
    loading: boolean;
    calles?: any;
    error: {
        status?: number;
        message: string;
        isPinError?: boolean;
    };
}


const initialState: Pros = {
    loading: false,
    calles: [],
    error: {
        status: 0,
        message: "",
        isPinError: false,
    },
};


const callesSlice = createSlice({
    name: "calles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCalles.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCalles.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.calles = payload;
        });
        builder.addCase(getCalles.rejected, (state, action: any) => {
            state.loading = false;
            state.error = {
                status: action.payload.response.status,
                message: action.payload.response.data.detail,
            };
        });
    },
});


export default callesSlice.reducer;





