import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
  Spinner,
  Box,
  useColorModeValue,
  StackDivider,
  Icon,
  useBreakpointValue,
  IconProps,
  Collapse,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Circle,
  Grid,
  VStack,
  SimpleGrid,
  Card,
  CardBody,
  Divider,
  HStack,
} from "@chakra-ui/react";
import axios, { Axios } from "axios";
import { ReactElement, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { axiosLoggedOutConfig } from "../utilities/Axios";
import { useDispatch, useSelector } from "react-redux";
import { FiCreditCard, FiDownload, FiFileText, FiFolder, FiMail, FiMap, FiPhone, FiPlusCircle, FiSearch, FiSettings, FiUser } from "react-icons/fi";
import { SidebarWithHeader } from "./dashboard/SidebarWithHeader";
import { AppDispatch } from "../store/StoreRoot";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 1 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#95c840" />
      <circle cx="244" cy="106" r="139" fill="#4093C8" />
      <circle cy="291" r="139" fill="#95c840" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#4093C8" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#95c840" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#4093C8" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#95c840" />
    </Icon>
  );
};


export const EditProfile = () => {
  //@ts-ignore
  const dispatch: AppDispatch = useDispatch();
  //@ts-ignore
  const { user } = useSelector((state) => state.profile);
  return (
    <>
      <SidebarWithHeader>
        <Box
          textAlign="center"
          fontSize="xl"
          bg={useColorModeValue("gray.100", "gray.900")}
        >
          <Grid minH="100vh" p={3}>
            <VStack spacing={0}>
              <Box w={"90%"} mt={10} paddingBottom={10} paddingRight={4} paddingLeft={4}>
                <Heading fontWeight={600} textAlign={"left"}>¡Hola, {user?.first_name}!</Heading>
                <Text textAlign={"left"}>Recordá mantener siempre tus datos actualizados.</Text>
              </Box>

              <Box w={"90%"} mt={10} paddingBottom={10} paddingRight={4} paddingLeft={4}>
                <Card minH="83px" mt={4} boxShadow={"null"}>
                  <CardBody>

                    <Box flex='1' paddingTop={4} paddingBottom={4}>
                      <HStack spacing={10} justify={"space-around"}>
                        <Box textAlign={"left"} color={"gray.700"}>
                          <Text fontWeight={600}>Nombre y apellido</Text>
                          <Text >{user?.first_name} {user?.last_name}</Text></Box>
                        <Box textAlign={"left"} color={"gray.700"}>
                          <Text fontWeight={600}>Nivel de seguridad</Text>
                          <Text >Nivel 2</Text></Box>


                      </HStack>
                    </Box>

                  </CardBody>
                </Card>

              </Box>
              <Box w={"90%"} mt={10} paddingBottom={10} paddingRight={4} paddingLeft={4}>
                <Card minH="83px" mt={4} boxShadow={"null"}>
                  <CardBody>
                    <Box w={"100%"} textAlign={"left"} ml={5}>
                      <Text fontSize={"3xl"} color={"gray.700"} fontWeight={500}><Icon as={FiUser} fontSize={30} color={"muni.celeste"} mr={2} />
                        Tus datos personales</Text>
                    </Box>
                    <Divider />
                    <Box flex='1' paddingTop={4} paddingBottom={4}>
                      <HStack spacing={10} justify={"space-around"}>
                        <Box textAlign={"left"} color={"gray.500"}>
                          <Text fontWeight={600}>Tipo y N° de documento</Text>
                          <Text >DNI {user?.dni}</Text></Box>
                        <Box textAlign={"left"} color={"gray.500"}>
                          <Text fontWeight={600}>Fecha de nacimiento</Text>
                          <Text >03/10/2002</Text></Box>


                      </HStack>
                    </Box>

                    <Button bg={"muni.celeste"} color={"white"} w={"100%"} fontWeight={"bold"} fontSize={18}>EDITAR MIS DATOS</Button>
                  </CardBody>
                </Card>

              </Box>

              <Box w={"90%"} mt={10} paddingBottom={10} paddingRight={4} paddingLeft={4}>
                <Card minH="83px" mt={4} boxShadow={"null"}>
                  <CardBody>
                    <Box w={"100%"} textAlign={"left"} ml={5} >
                      <Text fontSize={"3xl"} color={"gray.700"} fontWeight={500} alignSelf={"center"} display="flex" alignItems="center"><Icon as={FiMap} fontSize={30} color={"muni.celeste"} mr={2} />
                        Dirección</Text>
                      <Text fontSize={18} mt={-2} ml={9} color={"gray.500"} fontWeight={500}>
                        Agregá tu dirección para mostrarte información en base a tu ubicación</Text>
                    </Box>
                    <Divider />

                    <Box w={"100%"} textAlign={"left"} ml={5} alignSelf={"center"} display="flex" alignItems="center" mt={2}>
                      <Icon as={FiPlusCircle} fontSize={30} color={"muni.verde"} mr={2} />
                      <Link textAlign={"left"} color={"gray.700"}>Agregar dirección de tu domicilio</Link>
                    </Box>

                  </CardBody>
                </Card>

              </Box>


              <Box w={"90%"} mt={10} paddingBottom={10} paddingRight={4} paddingLeft={4}>
                <Card minH="83px" mt={4} boxShadow={"null"}>
                  <CardBody>
                    <Box w={"100%"} textAlign={"left"} ml={5} >
                      <Text fontSize={"3xl"} color={"gray.700"} fontWeight={500} alignSelf={"center"} display="flex" alignItems="center"><Icon as={FiPhone} fontSize={30} color={"muni.celeste"} mr={2} />
                        Telefono</Text>
                      <Text fontSize={18} mt={-2} ml={9} color={"gray.500"} fontWeight={500}>
                        Agregá un número de celular para recuperar tu cuenta vía SMS</Text>
                    </Box>
                    <Divider />

                    <Box w={"100%"} textAlign={"left"} ml={5} alignSelf={"center"} display="flex" alignItems="center" mt={2}>
                      <Icon as={FiPlusCircle} fontSize={30} color={"muni.verde"} mr={2} />
                      <Link textAlign={"left"} color={"gray.700"}>Agregar número de celular</Link>
                    </Box>

                  </CardBody>
                </Card>

              </Box>

              <Box w={"90%"} mt={10} paddingBottom={10} paddingRight={4} paddingLeft={4}>
                <Card minH="83px" mt={4} boxShadow={"null"}>
                  <CardBody>
                    <Box w={"100%"} textAlign={"left"} ml={5} >
                      <Text fontSize={"3xl"} color={"gray.700"} fontWeight={500} alignSelf={"center"} display="flex" alignItems="center"><Icon as={FiMail} fontSize={30} color={"muni.celeste"} mr={2} />
                        Correo electrónico</Text>
                      <Text fontSize={18} mt={-2} ml={9} color={"gray.500"} fontWeight={500}>
                        Agregá un correo alternativo como método de recuperación de tu cuenta</Text>
                    </Box>
                    <Divider />

                    <Box w={"100%"} textAlign={"left"} ml={5} alignSelf={"center"} display="flex" alignItems="center" mt={2}>
                      <Icon as={FiPlusCircle} fontSize={30} color={"muni.verde"} mr={2} />
                      <Link textAlign={"left"} color={"gray.700"}>Agregar dirección de correo electronico</Link>
                    </Box>

                  </CardBody>
                </Card>

              </Box>




            </VStack>
          </Grid>
        </Box >
      </SidebarWithHeader>
    </>
  );
};
