import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "../screens/auth/_duck";
import registerSlice from "../screens/auth/_duck/registerSlice";
import homeSlice from "../screens/auth/_duck/homeSlice";
import callesSlice from "../screens/auth/_duck/callesSlice";

export default combineReducers({
  profile: userSlice,
  register: registerSlice,
  home: homeSlice,
  calles: callesSlice
});
