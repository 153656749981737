import { Outlet, Navigate } from 'react-router-dom'
import {SidebarWithHeader} from '../screens/dashboard/SidebarWithHeader';

const checkLogin = () => {
    return localStorage.getItem("isLoggedIn") === "true";
};


const LoggedInRoutes = () => {
    return (
        checkLogin() ? <Outlet /> : <Navigate to="/" />
    )
}

export default LoggedInRoutes